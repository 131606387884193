import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Landing from './Landing'
import { PrivacyPolicy } from './static_pages/PrivacyPolicy'
import PricingPage from './static_pages/PricingPage'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AITokenProvider } from './AITokenContext';
import { handleGetUuid } from './utils/uniqueid';
import InternalNav from './pages/InternalNav';

export default function App() {
    const userInfo = localStorage.getItem("userInfo");

    const email = JSON.parse(userInfo)?.email;
    const uuid = localStorage.getItem("_og_id");
    if (uuid === null || uuid === 'undefined') {
        handleGetUuid();
    }

    return (
        <AITokenProvider email={email} uuid={uuid}>
        <GoogleOAuthProvider clientId="1008435001504-0hat8gu9cerhovfu2kmdinhu8u2uujfu.apps.googleusercontent.com">
        
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route path="/learning" element={<LearnLand />} /> */}
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/auth/google" element={<Landing />} />
            {/* <Route path="/addplaylist" element={<AddPlaylist />} /> */}
            <Route path="/internal" element={<InternalNav />} />            
        </Routes>
        </BrowserRouter>
        
        </GoogleOAuthProvider>
        </AITokenProvider>
    )
    }
