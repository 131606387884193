import React, { useEffect, useState, useContext } from "react";


import {
  Paper,
  BottomNavigationAction,
  BottomNavigation,
  Container,
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  Link,
} from "@mui/material";
import PolicyIcon from "@mui/icons-material/Policy";
import TwitterIcon from "@mui/icons-material/Twitter";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { PrivacyPolicy } from "./static_pages/PrivacyPolicy";
import CustomGoogleLoginButton from "./components/CustomGoogleLoginButton";
// import { getGetHeaders } from "./utils/headers";
import { AITokenContext } from "./AITokenContext";
import PricingPage from "./static_pages/PricingPage";
import axios from "axios";

function Landing() {
  const [showTranscriptionCard, setShowTranscriptionCard] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [profile, setProfile] = useState([]);
  const [showPricingPage, setShowPricingPage] = useState(false);
  const { aITokens } = useContext(AITokenContext);
  const [aITokenUsage, setAITokenUsage] = aITokens;
  

  function getTempEmail() {
    const uuid = localStorage.getItem("_og_id");
    const trialEmail = `${uuid}@ytlearn.ai`;
    return trialEmail;
  }

  useEffect(() => {
    async function fetchit() {
      const email = profile?.email ? profile?.email : getTempEmail();
      await axios
        .get(`${process.env.REACT_APP_YTL_BE}/api/token_usage?email=${email}`, {
          responseType: "json",
        })
        .then((response) => {
          console.log(`in hook, response.data is: ${response.data}`);
          localStorage.setItem("aITokenUsage", response.data);
          setAITokenUsage(response.data);
          if (response.data[2] <= 0) {            
            setShowPricingPage(true);
            console.log(aITokenUsage)
          }
        })
        .catch((error) => {
          console.error(`Error fetching token usage, ERROR: ${error}`);
        });
    }
    fetchit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // const handleSetAITokens = (value) => {
  //   console.log(`in handleSetAITokens setting tokens to ${value}`);
  //   setAITokenUsage(value);
  // };

  
  function handleSetProfile(value) {
    setProfile(value);
  }
  function handlePrivacyPolicy() {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }

  
  function handleSubmit(event) {
    event.preventDefault();
    setShowTranscriptionCard(true);
    setShowLoading(false);
    window.location.href = "/internal";   
  }



  const letsGoMax = { lg: "100%", md: "100%", xs: "100%", sm: "100%" };
  const imgMax = { lg: "384px", md: "384px", xs: "324px", sm: "324px" };
  const pageImgMax = { lg: "84px", md: "84px", xs: "84px", sm: "84px" };
  const imgWidth = { lg: "250px", md: "250px", xs: "250px", sm: "250px" };
  const colLanding = { xs: 1, sm: 8, md: 12 };
  const colWorking = { xs: 8, sm: 8, md: 12 };
  const getColumnWidths = !showLoading ? colLanding : colWorking;
  const getMaxWidth = !showLoading ? imgMax : pageImgMax;
  const getHeaderBoxHeight = !showLoading ? "auto" : "auto";
  const getHeaderBoxWidth = !showLoading ? "fit-content" : "auto";
  const getLogoPadding = !showLoading ? "0%" : "20%";
  const getLogoLeftPadding = !showLoading
    ? { lg: "40px", md: "40px", xs: "0px", sm: "0px" }
    : "0px";

  return (
    <>
      <Container
        sx={{
          marginTop: "1%",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundImage:  showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "url(./hero_image2.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginLeft: "0px",
          marginRight: "0px",
          maxWidth: "100%!important",
        }}
      >
        <Box
          sx={{
            width: "100%",
            // margin: "auto",
            maxWidth: getHeaderBoxWidth,
            height: getHeaderBoxHeight,
          }}
        >
          <Grid container spacing={{ xs: 0, md: 3 }} columns={getColumnWidths}>
            <Grid item>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: imgWidth,
                  maxWidth: getMaxWidth,
                  margin: "auto",
                  marginTop: ".7rem",
                  float: "right",
                  paddingLeft: getLogoLeftPadding,
                  paddingRight: getLogoPadding,
                  visibility:
                    showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
                  display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
                }}
                alt="YTLearn AI logo"
                src="./YTLearnAIYellowNoBG.png"
              />
            </Grid>

            <Grid
              item
              xs={4}
              md={8}
              lg={8}
              sx={{
                marginTop: "3rem",

                visibility:
                  showLoading || !showTranscriptionCard || showPrivacyPolicy ? "hidden" : "hidden",
                display:
                  showLoading || !showTranscriptionCard || showPrivacyPolicy ? "none" : "none",
              }}
            ></Grid>

            <Grid item={true} xs={2}>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "384",
                  maxWidth: getMaxWidth,
                  marginBottom: "5%",
                  visibility:
                    showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "hidden",
                  display:
                    showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "none",
                }}
                alt="app screenshot"
                src="./landing_screenshots.png"
              />
              <Box
                sx={{
                  visibility:
                    showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "hidden",
                  display:
                    showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "none",
                }}
              >
                <CustomGoogleLoginButton
                  showLoading={showLoading}
                  showTranscriptionCard={!showTranscriptionCard}
                  profile={profile}
                  handleSetProfile={handleSetProfile}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            visibility:
              showLoading || !showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
            display: showLoading || !showTranscriptionCard || showPrivacyPolicy ? "none" : "",
            margin: "auto",
            width: "100%",
            maxWidth: "fit-content",
          }}
        ></Box>

        {!showLoading && !showTranscriptionCard && !showPrivacyPolicy && (
          <>
            <Divider
              variant="middle"
              sx={{ marginBottom: "1%", marginTop: "2px" }}
            />
            <Box
              sx={{
                margin: "auto",
                width: {sm: "70%", md: "40%", lg: "40%"},
                maxWidth: "fit-content",
                paddingTop: "1em",
              }}
            >
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "4.35em",
                  fontWeight: "500",
                  color: "#FFD700",
                }}
              >
                Elevate Your YouTube Learning Experience with YTLearn AI
              </Typography>
            </Box>
          </>
        )}
        <Divider variant="middle" sx={{ marginBottom: "1%" }} />
        {!showLoading && (
          <Box
            component="div"
            sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
          >
            <Box
              component="div"
              sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
            ></Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              alignContent="center"
              sx={{
                width: "100%",
                maxWidth: "fit-content",
                margin: "auto",
              }}
            >
              <Box
                component="div"
                sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
              >
                <Button
                  size="large"
                  variant="contained"
                  type="submit"                  
                  sx={{
                    width: letsGoMax,
                    fontSize: "2rem",
                    borderRadius: 7,
                    marginTop: "5%",
                    marginBottom: "25%",
                    backgroundColor: "#FFD700",
                    "&:hover": { backgroundColor: "#F1E7B2" },
                    visibility:
                      showLoading || showTranscriptionCard || showPrivacyPolicy
                        ? "hidden"
                        : "visible",
                    display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
                  }}
                >
                  Let's Go!
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ marginRight: "2em" }}>          
          {showPrivacyPolicy && <PrivacyPolicy />}
          {showPricingPage && <PricingPage />}
        </Box>       
      </Container>

      <Grid
        container
        columns={16}
        sx={{
          display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
          visibility:
            showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            minHeight: "300px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#003366",
            color: "#F5F5F5",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ padding: "3.35em" }}>
            Have you ever lost yourself in the endless abyss of YouTube videos,
            only to realize that the valuable knowledge you absorbed seemed to
            vanish as quickly as it appeared? Imagine this: not just watching
            YouTube, but truly learning from it. 
            <br />
            <br />
            Introducing <span style={{fontWeight:"bold"}}>YTLearn.ai</span> – the
            solution to your knowledge retention woes.
            <br />
            <br />
            We get it – those captivating videos are hard to resist. But here's
            the catch: can you confidently discuss the subject later? Could you
            tackle a quiz or even write a short essay about it? YTLearn.ai is
            here to bridge the gap between passive watching and active learning.
          </Typography>
        </Box>
      </Grid>

      <Grid
        container
        columns={16}
        sx={{
          display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
          visibility:
            showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
        }}
      >
        <Box
          sx={{
            width: "60%",
            minWidth: "60%",
            margin: "auto",
            display: "flex",
            minHeight: "300px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {/* <YouTube 
                  videoId="o_743pawHc4" 
                  opts={opts}
                  style={{paddingTop:"2rem", paddingBottom:"2rem"}}/>               */}
        </Box>
      </Grid>

      <Grid
        container
        columns={16}
        sx={{
          display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
          visibility:
            showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            minHeight: "300px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#003366",
            color: "#F5F5F5",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ padding: "3.35em" }}>
            Say goodbye to the frustration of forgetting crucial details from
            those insightful videos. YTLearn.ai empowers you to remember,
            retain, and apply your newfound knowledge. 
            <br />
            <br />
            Unlock a world where YouTube isn't just a source of entertainment,
            but a genuine tool for growth. Join countless others who have
            already transformed their learning journey with YTLearn.ai. Don't
            just watch – learn, remember, and thrive.
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        columns={16}
        sx={{
          display: showLoading || showTranscriptionCard || showPrivacyPolicy ? "none" : "",
          visibility:
            showLoading || showTranscriptionCard || showPrivacyPolicy ? "hidden" : "visible",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            minHeight: "300px",
            alignItems: "center",
            marginBottom: "2em",
            justifyContent: "center",
            backgroundColor: "#F5F5F5",
            color: "#003366",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ padding: "3.35em" }}>
            Ready to take the leap? <Link onClick={handleSubmit}>Sign up</Link>{" "}
            for early access and embark on a new era of meaningful YouTube
            learning with YTLearn.ai today!"
          </Typography>
        </Box>
      </Grid>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels sx={{ backgroundColor: "#fff" }}>
          <BottomNavigationAction
            sx={{ color: "#003366" }}
            label="Privacy"
            icon={<PolicyIcon />}
            onClick={handlePrivacyPolicy}
          />
          <BottomNavigationAction
            sx={{ color: "#003366" }}
            label="Twitter"
            icon={<TwitterIcon />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
}

export default Landing;
