import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import QuizIcon from "@mui/icons-material/Quiz";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import StyleIcon from "@mui/icons-material/Style";
import ComponentNameDebug from "../../hooks/ComponentNameDebug";

export default function RetainLearningOptions({handleMenuSelect, isAuthenticated}) {
    const linkStyle = {
        pointerEvents: isAuthenticated ? "auto" : "none",
        cursor: isAuthenticated ? "pointer" : "default",
        opacity: isAuthenticated ? "inherit" : "0.6",
      };
      
    return (
      <>
      <ComponentNameDebug component="RetainLearningOptions.js" />
      <List>
      {["Quiz", "Explain it to me", "Flash Cards"].map((text, index) => (
        <Link
          key={index}
          onClick={handleMenuSelect}
          underline="hover"
          color="#212121"
          sx={linkStyle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index === 0 ? (
                  <QuizIcon />
                ) : index === 1 ? (
                  <LocalLibraryIcon />
                ) : (
                  <StyleIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
    </>
    )
}
