import React, {useEffect, useState, createContext} from 'react';
import axios from "axios";
import { getPostHeaders } from "./utils/headers";

export const AITokenContext = createContext();


export const AITokenProvider = props => {
    const [aITokenUsage, setAITokenUsage] = useState(2000);
    // debugger;
    function getTempEmail() {
        const uuid = localStorage.getItem("_og_id");
        const trialEmail = `${uuid}@ytlearn.ai`;
        return trialEmail;
    }
    async function handleUser(trialEmail) {                
        const tempProfile = {
            email: trialEmail,
            given_name: "Guest",
            family_name: "User",
            picture: "https://ytlearn.ai/static/media/ytl_feLogo.5b3b3b3b.png",
            };
          let headers = getPostHeaders({ profile: tempProfile }, "POST");
          const response = await fetch(
            `${process.env.REACT_APP_YTL_BE}/api/user`,
            { ...headers }
          );
          // if (response.status === 401) {context.logout({})}
          const data = await response.json();
          console.log(data); 
          return trialEmail;
        }


    useEffect(() => {                
        const email = props?.email ? props?.email : getTempEmail();
        if (email.includes("@ytlearn.ai")) {
            // no account, create trial user 
            handleUser(email);
        }
        async function FetchAITokenUsage() {                        
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_YTL_BE}/api/token_usage?email=${email}`,
                responseType: 'json',  
            })
            .then(response => {                    
                setAITokenUsage(response.data);                
            }
            )
            .catch(error => {
                console.error(`Error fetching token usage, ERROR: ${error}`);                
            });            
            };
            FetchAITokenUsage(email);
        }, [props]);


    // const handleUpdateAITokenUsage = (value) => {
    //     console.log(`updating AITokensUsed usage: ${value}`);
    //     setAITokenUsage(value);
    // };


    return(
        <AITokenContext.Provider value = {{aITokens: [aITokenUsage, setAITokenUsage]}}>
            {props.children}
        </AITokenContext.Provider>
    )
}
