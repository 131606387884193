import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import ExploreIcon from "@mui/icons-material/Explore";
import LockIcon from "@mui/icons-material/Lock";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ChildVideos from "./ChildVideos";
import ComponentNameDebug from "../../hooks/ComponentNameDebug";

export default function LibraryOptions({
  email,
  handleMenuSelect,
  isAuthenticated,
  handleDisplayVideoDetails,
  libraryUpdated,
}) {
  const [open, setOpen] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  // const [childOpen, setChildOpen] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [standAloneVideos, setStandAloneVideos] = useState([]);
  const [playlistItems, setPlaylistItems] = useState([]);
  const [selectedPlaylistName, setSelectedPlaylistName] = useState("");
  const [parent, setParent] = useState("");

  const handleShowLearningDetails = (video_id) => {
    console.log(`PARENT handleShowLearningDetails, ${video_id}`);
    handleDisplayVideoDetails(video_id);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handlePlaylistClick = (event) => {
    // debugger;
    console.log(`outerText: ${event.target?.outerText}`);
    setParent(selectedPlaylistName)
    setSelectedPlaylistName(event.target?.outerText);
    setPlaylistOpen(true);
    // setChildOpen(true);
  };

  const handleSetSelectedChildVideo = (video_name) => {
    console.log(`in handleSetSelectedChildVideo, ${video_name}`);
    setSelectedPlaylistName(video_name);
  };

  const processHandleMenuSelect = (event) => {
    console.log(`in processHandleMenuSelect, ${event.target.textContent}`);
    setSelectedPlaylistName(event.target.textContent);
    if (event.target.textContent === "Add to Library") {
      setPlaylistOpen(!playlistOpen);
      setOpen(!open);
    }
    // else {
    //   const localPlaylistItems = JSON.parse(localStorage.getItem("playlistItems"));
    //   const standAloneVideos = JSON.parse(localStorage.getItem("standAloneVideos"))
    //   // debugger;
    //   const matchingPlaylistVideo = localPlaylistItems.find(video => video.video_name === event.target.textContent);
    //   matchingPlaylistVideo && handleDisplayVideoDetails(matchingPlaylistVideo?.video_id) || standAloneVideos && handleDisplayVideoDetails(standAloneVideos?.video_id)
    // }

    handleMenuSelect(event);
  };

  const linkStyle = {
    pointerEvents: isAuthenticated ? "auto" : "none",
    cursor: isAuthenticated ? "pointer" : "default",
    opacity: isAuthenticated ? "inherit" : "0.6",
  };

  // get all standalone videos for user
  useEffect(() => {
    async function getStandAloneVideos() {
      const standAloneVideos = await fetch(
        `${process.env.REACT_APP_YTL_BE}/api/videos?email=${email}&debug=1`,
        {
          method: "GET",
        }
      );
      const data = await standAloneVideos.json();
      setStandAloneVideos(data);
      setOpen(true);
      localStorage.setItem("standAloneVideos", JSON.stringify(data));
    }
    email && getStandAloneVideos();
  }, [email, libraryUpdated]);

  // get all playlists for user
  useEffect(() => {
    async function getPlaylists() {
      const playlists = await fetch(
        `${process.env.REACT_APP_YTL_BE}/api/playlists?email=${email}`,
        {
          method: "GET",
        }
      );
      const data = await playlists.json();
      setPlaylists(data);
      localStorage.setItem("playlists", JSON.stringify(data));
      setOpen(true);
    }
    if (email) {
      getPlaylists();
    }
  }, [email]);

  function handleGetPlaylistItems(event) {
    // console.log(`PARENT in handleGetPlaylistItems, ${event.target.textContent}`);
    async function getPlaylistItems() {
      const playlistItems = await fetch(
        `${process.env.REACT_APP_YTL_BE}/api/playlist_videos?name=${event.target.textContent}&email=${email}`,
        {
          method: "GET",
        }
      );
      const data = await playlistItems.json();
      // console.log(data);
      localStorage.setItem("playlistItems", JSON.stringify(data));
      setPlaylistItems(data);
      handleMenuSelect(event);
    }
    getPlaylistItems();
  }

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ComponentNameDebug component="LibraryOptions.js" />
      {["Add to Library", "Explore Library"].map((text, index) => (
        <Link
          key={index + text}
          // onClick={handleMenuSelect}
          onClick={processHandleMenuSelect}
          underline="hover"
          color="#212121"
          sx={
            index !== 1
              ? linkStyle
              : { pointerEvents: "auto", cursor: "pointer", opacity: "inherit" }
          }
        >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                {!isAuthenticated && index !== 1 ? (
                  <LockIcon />
                ) : index === 0 ? (
                  <AddIcon />
                ) : (
                  <ExploreIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
              {index === 1 && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {index === 1 ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {standAloneVideos.map((standAloneVideo, index) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      key={index}
                      onClick={() =>
                        handleShowLearningDetails(standAloneVideo.video_name)
                      }
                    >
                      <ListItemIcon>
                        <PlayCircleOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary={standAloneVideo.video_name} />
                    </ListItemButton>
                  ))}
                </List>
                <List component="div" disablePadding>
                  {playlists.map((playlist, index) => (
                    <ListItem
                      key={playlist.playlist_name}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{ pl: 4 }}
                        key={index}
                        onClick={handlePlaylistClick}
                      >
                        <ListItemIcon>
                          <SubscriptionsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={playlist.playlist_name}
                          onClick={handleGetPlaylistItems}
                        />
                        {playlist.playlist_name === selectedPlaylistName &&
                          (playlistOpen ? <ExpandLess /> : <ExpandMore />)}
                      </ListItemButton>
                      <Collapse
                        in={
                          (parent && (playlist.playlist_name === selectedPlaylistName)) ||
                          (playlistOpen &&
                            playlist.playlist_name === selectedPlaylistName)
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <ChildVideos
                          playlistItems={playlistItems}
                          handleSetSelectedChildVideo={
                            handleSetSelectedChildVideo
                          }
                          handleDisplayVideoDetails={handleDisplayVideoDetails}
                        />
                      </Collapse>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </ListItem>
        </Link>
      ))}
    </List>
  );
}
