import React, { useEffect, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomGoogleLoginButton from "../components/CustomGoogleLoginButton";
import LearnLand from "../components/LearnLand";
import AddPlaylist from "../components/AddPlaylist";
import AddSingleVideo from "../components/AddSingleVideo";
import LibraryOptions from "../components/menu/LibraryOptions";
import AccountOptions from "../components/menu/AccountOptions";
import LearningProfile from "../components/profile/LearningProfile";
import ComponentNameDebug from "../hooks/ComponentNameDebug";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function InternalNav() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  // const [showLibrary, setShowLibrary] = useState(false);
  // const [playlistAdded, setPlaylistAdded] = useState(false);
  const [showAddPlaylist, setShowAddPlaylist] = useState(false);
  const [showLearningProfile, setShowLearningProfile] = useState(true);
  const [profile, setProfile] = useState([]);
  const [showLearningDetails, setShowLearningDetails] = useState(false);
  const [selectedPlayListItem, setSelectedPlayListItem] = useState([]);
  // const [selectedParent, setSelectedParent] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [libraryUpdated, setLibraryUpdated] = useState(false);
  const [email, setEmail] = useState("");
  const userInfo = localStorage.getItem("userInfo");

  useEffect(() => {
    if (userInfo?.length > 0) {
      setEmail(JSON.parse(userInfo)?.email);
      setIsAuthenticated(true);
    } else {
      const ogId = localStorage.getItem("_og_id");
      setEmail(`${ogId}@ytlearn.ai`);
      setIsAuthenticated(false);
    }
    setShowLearningDetails(false);
  }, [userInfo]);

  useEffect(() => {
    console.log("************** InternalNav.js line 104 **************");
    const localPlaylistItems = JSON.parse(
      localStorage.getItem("standAloneVideos")
    );
    if (localPlaylistItems !== null && localPlaylistItems.length > 0) {
      const matchingVideo = localPlaylistItems.find(
        (video) => video?.video_name === selectedPlayListItem
      );

      if (matchingVideo !== undefined) {
        setSelectedVideoUrl(matchingVideo?.video_url);
        setSelectedVideoId(matchingVideo.video_id);
        setShowLearningDetails(true);
      } else {
        setShowLearningDetails(false);
      }
    } else {
      setShowLearningDetails(false);
    }
  }, [selectedPlayListItem]);

  // const callback = () => {
  //   setPlaylistAdded(!playlistAdded);
  //   console.log("triggered callback, playlistAdded: " + playlistAdded);
  // };

  const refreshCallback = () => {
    setShowLearningProfile(true);
    setShowAddPlaylist(false);
    setShowLearningDetails(false);
    setLibraryUpdated(!libraryUpdated);
  };

  function handleSetProfile(value) {
    setProfile(value);
  }

  // function handleSetShowLearningDetails(value) {
  //   setSelectedPlayListItem(value);
  //   setShowLearningDetails(true);
  // }

  // function handleSetShowSingleVideoLearningDetails(value) {
  //   console.log("handleSetShowSingleVideoLearningDetails");
  //   setSelectedPlayListItem(value);
  //   setShowLearningDetails(true);
  //   const localPlaylistItems = localStorage.getItem("playlistItems");
  //   const matchingVideo = localPlaylistItems.find(video => video.video_name === value);
  //   setSelectedVideoId(matchingVideo.video_id);
  // }

  // TODO: this is duplicated in CustomGoogleLoginButton.js and ResponsiveAppBar.js
  const logOut = () => {
    googleLogout();
    localStorage.removeItem("userInfo");
    localStorage.removeItem("aiTokenUsage");
    localStorage.removeItem("playlistItems");
    handleSetProfile(null);
    console.log("logging out");
    window.location.href = "/";
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDisplayVideoDetails = (value) => {
    // debugger;
    console.log(`in handleShowLearningDetails: ${value}`);
    // setSelectedParent(selectedPlayListItem);
    setSelectedPlayListItem(value);
    console.log("************** InternalNav.js line 171 **************");
    setShowLearningDetails(true);
    setShowLearningProfile(false);
  };

  const handleMenuSelect = (event) => {
    // debugger;
    console.log(event?.target?.innerText);
    console.log(`selectedPlaylistItem's value: ${selectedPlayListItem}`);

    if (event.target?.innerText === "") {
      window.location.href = "/";
    }

    if (event.target.innerText === "Home") {
      setShowLearningProfile(true);
      // setShowLibrary(false);
      setShowAddPlaylist(false);
      setShowLearningDetails(false);
    } else if (event.target.innerText === "Logout") {
      logOut();
      window.location.href = "/";
    } else if (event.target.innerText === "My Profile") {
      window.location.href = "/profile";
    } else if (event.target.innerText === "Learning Nuggets") {
      window.location.href = "/learningnuggets";
    } else if (event.target.innerText === "Quizzes") {
      window.location.href = "/quizzes";
    } else if (event.target.innerText === "Style Guide") {
      window.location.href = "/styleguide";
    } else if (event.target.innerText === "Explore Library") {
      // setShowLibrary(true);
      setShowAddPlaylist(false);
      setShowLearningProfile(false);
      setShowLearningDetails(false);
    } else if (event.target.innerText === "Add to Library") {
      setShowAddPlaylist(true);
      setShowLearningProfile(false);
      setShowLearningDetails(false);
      // setShowPlaylists(false);
    } else if (event.target.innerText === selectedPlayListItem) {
      // no match, so must be a video
      // debugger;
      console.log("************** InternalNav.js line 214 **************");
      setShowLearningDetails(true);
      setShowAddPlaylist(false);
      // setSelectedPlayListItem(event.target.innerText);
    } else if (event.target.innerText !== undefined) {
      // debugger;
      setShowLearningDetails(false);
      setShowAddPlaylist(false);
    }
  };

  return (
    <>
      <ComponentNameDebug component="InternalNav.js" />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ backgroundColor: "#003366" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "3rem",
                paddingRight: "1%",
                cursor: "pointer",
              }}
              alt="YTLearn AI logo"
              src="./YTLearnAIYellowNoBG.png"
              onClick={handleMenuSelect}
            />

            <Typography variant="h6" noWrap component="div">
              YTLearn AI
            </Typography>

            <Box sx={{ display: "contents" }}>
              <CustomGoogleLoginButton
                showLoading={false}
                showTranscriptionCard={false}
                profile={profile}
                handleSetProfile={handleSetProfile}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <LibraryOptions
            email={email}
            handleMenuSelect={handleMenuSelect}
            isAuthenticated={isAuthenticated}
            handleDisplayVideoDetails={handleDisplayVideoDetails}
            libraryUpdated={libraryUpdated}
          />
          <Divider />
          {/* <RetainLearningOptions
          handleMenuSelect={handleMenuSelect}
          isAuthenticated={isAuthenticated}
        />

        <Divider /> */}
          <AccountOptions
            handleMenuSelect={handleMenuSelect}
            isAuthenticated={isAuthenticated}
          />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />

          {isAuthenticated && showLearningProfile && email && (
            <LearningProfile email={email} />
          )}

          {!showAddPlaylist && !isAuthenticated && (
            <AddSingleVideo email={email} refreshCallback={refreshCallback} />
          )}
          {showAddPlaylist && isAuthenticated && (
            <AddPlaylist email={email} refreshCallback={refreshCallback} />
          )}

          {showLearningDetails && (
            <LearnLand
              refreshCallback={refreshCallback}
              selectedVideoId={selectedVideoId}
              selectedVideoUrl={selectedVideoUrl}
              video_name={selectedPlayListItem}
              email={email}
            />
          )}
        </Main>
      </Box>
    </>
  );
}
