// Main page to configure:
// - which playlist to use
// - frequency of updates
// For each video in the playlist, the following is displayed:
// - title
// - description
// - thumbnail
// - tags
// - publish date
// - Summary
// - Transcript
// - Bullet points with timestamps
// Learning options:
// - create tl;dr
// - create summary
// - create bullet points
// - Create flashcards
// - Create quiz
// - Create mindmap
// - Enable spaced repetition

import React from "react";
import { Grid, Box, TextField, Button } from "@mui/material";
import axios from "axios";
import ComponentNameDebug from "../hooks/ComponentNameDebug";

export default function AddPlaylist({ email, refreshCallback }) {
  const handleAddPlaylist = () => {    
    async function addPlaylist() {
      // create a playlist and return a list of its videos
      const playlist = await axios.post(
        `${process.env.REACT_APP_YTL_BE}/api/playlists`,
        {
          playlistUrl: document.getElementById("PlaylistUrl").value,
          email: email,
        }
      );
      console.log(playlist);
      refreshCallback();
    }
    addPlaylist();
  };

  const handleAddVideo = () => {    
    async function addVideo() {      
      // todo: write this endpoint on backend
      const video = await axios.post(
        `${process.env.REACT_APP_YTL_BE}/api/videos`,
        {
          videoUrl: document.getElementById("VideoUrl").value,
          email: email,
        }
      );
      console.log(video);
      refreshCallback();
    }
    addVideo();
  };


  return (
    <div>  
      <ComponentNameDebug component="AddPlaylist.js" />
                 
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            marginLeft: "4rem",
            marginRight: "4rem",
            marginTop: "1rem",
            marginBottom: "5rem",
          }}
        >
          <Box component="h2" sx={{ padding: "1%", color: "#003366" }}>
            Add a new playlist
          </Box>
          <Box sx={{ width: "80%", display: "flex" }}>
            <TextField
              id="PlaylistUrl"
              label="Playlist URL"
              placeholder="https://www.youtube.com/playlist?list=PLqZXAkvF1bPNQER9mLmDbntNfSpzdDIU5"
              variant="outlined"
              fullWidth={true}
            />
            <Button
              onClick={handleAddPlaylist}
              variant="contained"
              sx={{
                marginLeft: "1rem",
                backgroundColor: "#003366",
                "&:hover": { backgroundColor: "#E91E63" },
              }}
            >
              Add
            </Button>
          </Box>

          {/* TODO resuse component AddSingleVideo for this */}
          <Box component="h2" sx={{ padding: "1%", color: "#003366" }}>
            Add a Video
          </Box>
          <Box sx={{ width: "80%", display: "flex" }}>
            <TextField
              id="VideoUrl"
              label="Video URL"
              placeholder="https://www.youtube.com/watch?v=iKYHf22qVdM"
              variant="outlined"
              fullWidth={true}
            />
            <Button
              onClick={handleAddVideo}
              variant="contained"
              sx={{
                marginLeft: "1rem",
                backgroundColor: "#003366",
                "&:hover": { backgroundColor: "#E91E63" },
              }}
            >
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
