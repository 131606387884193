import React from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
// import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import QuizIcon from '@mui/icons-material/Quiz';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { UserContext } from "../shared/UserContext";
import ComponentNameDebug from "../hooks/ComponentNameDebug";


export default function ActionButtonGroup({ refreshCallback, thisVideoId }) {
//   const email = useContext(UserContext);
const email = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))?.email;
    
    const handleDelete = () => {
        async function deleteVideo() {
            await fetch(
                `${
                process.env.REACT_APP_YTL_BE
                }/api/videos?video_yt_id=${thisVideoId
                }&email=${email}`,
                {
                method: "DELETE",
                }
            );
            refreshCallback();
        }        
        deleteVideo();
    };

    const handleRefresh = () => {
      async function refreshVideo() {
        axios
        .post(`${process.env.REACT_APP_YTL_BE}/api/process_video`, {
          video_id: thisVideoId,
          email: email,
        })
        .then((data) => {
          console.log(data);          
          refreshCallback();
        });
      }
        refreshVideo();        
    };



  return (
    
    <>
    <ComponentNameDebug component="ActionButtonGroup.js" /> 
    <Stack direction="row" spacing={1} sx={{marginTop:"1em"}} >
      <IconButton color="error" aria-label="delete">
        <DeleteIcon onClick={handleDelete}/>
      </IconButton>
      <IconButton color="success" aria-label="refresh">
        <RefreshIcon onClick={handleRefresh}/>
      </IconButton>
      

      {/* <IconButton aria-label="quiz" color="primary">
        <QuizIcon />
      </IconButton>
      <IconButton color="secondary" aria-label="flash-cards">
        <ViewModuleIcon />
      </IconButton> */}
      
    </Stack>
    </>
    
  );
}
