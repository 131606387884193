import React, { useEffect, useState } from "react";
import { Typography, Box, Divider } from "@mui/material";
import ParagraphUnit from "../components/paragraphs/ParagraphUnit";
import ComponentNameDebug from "../hooks/ComponentNameDebug";

export default function LearningNuggets({
  videoBullets,
  videoTranscript,
  videoParagraphs, 
  videoSummary, 
  videoUrl
}) {
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    if (videoParagraphs?.length === 0) {
      return;
    }

    var paragraphs = videoParagraphs?.split("\n\n");
    var paragraphsWithTime = paragraphs.map((paragraph) => {
      var seconds = paragraph.split(":")[0];
      var text = paragraph.split(":")[1];
      return [seconds, text];
    });
    setParagraphs(paragraphsWithTime);
  }, [videoParagraphs]);

  return (
    <Box sx={{ display: "box" }}>   
    <ComponentNameDebug component="LearningNuggets.js" /> 
    {videoSummary && (
      <>
      <Typography variant="h2">Video Summary</Typography>
      <Typography>{videoSummary}</Typography>
      </>
    )}
      
      <Typography variant="h2">Key Bullet Points</Typography>
      {videoBullets && (
        <>
          {videoBullets
            ?.split("• ")
            .map((bullet, index) =>
              bullet.length > 3 ? (
                <Typography key={index}>• {bullet}</Typography>
              ) : (
                <> </>
              )
            )}
        </>
      )}

      {paragraphs && (
        <>
          <Divider
            component="div"
            role="presentation"
            sx={{
              paddingTop: "1em",
              paddingBottom: "1em",
              marginBottom: "1em",
            }}
          />
          <Typography variant="h2">Transcript Sections Summarized</Typography>
          {paragraphs.map((paragraph, index) => (
            <ParagraphUnit paragraph={paragraph} key={index} videoUrl={videoUrl}/>            
          ))}
        </>
      )}
    </Box>
  );
}
