import React, { useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export default function ChildVideos({ playlistItems, handleSetSelectedChildVideo, handleDisplayVideoDetails }) {
  const [activeVideo, setActiveVideo] = React.useState(null);

  const handleActiveChildVideo = (video_name) => {
    console.log(`CHILD handleActiveChildVideo, ${video_name}`);
    setActiveVideo(video_name);
    };


  useEffect(() => {
    console.log(`CHILD: activeVideo: ${activeVideo}`);
    if (activeVideo) {
        handleDisplayVideoDetails(activeVideo);    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVideo]);

  return (    
        <div>
      {playlistItems.map((playlistItem, index) => (        
        <ListItemButton
          sx={{ pl: 6 }}          
          onClick={() => handleActiveChildVideo(playlistItem.video_name)}
        >
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={playlistItem.video_name} />
        </ListItemButton>        
      ))}
    </div>
  );
}
