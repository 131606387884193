import React, { useState, useEffect } from "react";
import axios from "axios";
import LearningNuggets from "../pages/LearningNuggets";
import Box from "@mui/material/Box";
import { Typography, Link } from "@mui/material";
import ActionButtonGroup from "./ActionButtonGroup";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import ComponentNameDebug from "../hooks/ComponentNameDebug";
import RetainLearningOptions from "./menu/RetainLearningOptions"

export default function LearnLand({
  refreshCallback,
  selectedVideoId,
  selectedVideoUrl,
  video_name,
  email,
}) {
  const [videoBullets, setVideoBullets] = useState("");
  const [videoTranscript, setVideoTranscript] = useState("");
  const [videoParagraphs, setVideoParagraphs] = useState([]);
  const [videoSummary, setVideoSummary] = useState("");
  const [thisVideoId, setThisVideoId] = useState();


  useEffect(() => {
    const retrieveVideo = () => {
      axios
        .post(`${process.env.REACT_APP_YTL_BE}/api/retrieve_video`, {
          video_name: video_name,
          // video_id: selectedVideoId,
          email: email,
        })
        .then((data) => {          
          if (data.data.includes("Not Found") || data.data.includes("Not available")) {
            //  commented out for now, but this is where we would call processVideo()
            processVideo();
            return;
          } else {
            var result = JSON.parse(data.data);
            setVideoBullets(result.bullets);
            setVideoTranscript(result.transcript);
            setVideoParagraphs(result.paragraphs);
            setVideoSummary(result.summary);
            // setProcessed(true);
          }
        });
    };
    console.log(`executing retrieveVideo() from useEffect, LearnLand.js, video_name: ${video_name}`);
    if (video_name !== undefined || video_name.length !== 0) {
      console.log("###### LearnLand line 54 #######");
      let videos = JSON.parse(localStorage.getItem("standAloneVideos"));
      let video = videos.find(video => video.video_name === video_name);
      setThisVideoId(video?.video_yt_id);
    }
    console.log(`thisVideoId: ${thisVideoId}`);
    retrieveVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // debugger;
    if (selectedVideoId?.length === 0) {
      const allVideos = localStorage.getItem("standAloneVideos")
        ? JSON.parse(localStorage.getItem("standAloneVideos"))
        : [];
      const currentVideo = allVideos.find(
        (video) => video?.video_url === selectedVideoUrl
      );
      setThisVideoId(currentVideo?.video_yt_id);
    } else {
      setThisVideoId(selectedVideoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoUrl]);
  

  const processVideo = () => {
    axios
      .post(`${process.env.REACT_APP_YTL_BE}/api/process_video`, {
        video_id: thisVideoId,
        email: email,
      })
      .then((data) => {
        console.log(data);
        // setVideoBullets(replace_new_line(data.data.bullets));
        setVideoBullets(data.data.bullets);
        setVideoTranscript(data.data.transcript);
        setVideoParagraphs(data.data.paragraphs);
        setVideoSummary(data.data.summary);
      });
  };

  return (
    <>
    <ComponentNameDebug component="LearnLand.js" />
    <Box sx={{ display: "flex" }}>      
      <Paper elevation={1} sx={{ padding: "1em", marginRight: "1em", marginLeft: "-1em" }}>
      <Typography
        variant="h5"
        component="div"
        sx={{ flexGrow: 1, padding: ".25em", paddingRight: "1em",        
        marginRight: "1em",
        marginLeft: "-1em",
        paddingLeft: "1em" }}
      >
        <Link
          href={selectedVideoUrl}
          underline="hover"
          rel="noopener"
          target="_blank"
        >
          {video_name}
        </Link>
        <Divider sx={{marginTop:"1em"}} />
        <ActionButtonGroup refreshCallback={refreshCallback} thisVideoId={thisVideoId} />
        <Divider sx={{marginTop:"1em"}} />
        <RetainLearningOptions />
      </Typography>
      </Paper>
      
      <LearningNuggets
        videoBullets={videoBullets}
        videoTranscript={videoTranscript}
        videoParagraphs={videoParagraphs}
        videoSummary={videoSummary}
        videoUrl={selectedVideoUrl}
      />
    </Box>
    </>
  );
}
