import React, {useState} from "react";
import { Typography, Chip, Link } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

export default function ParagraphUnit({ paragraph, index, videoUrl }) {
    const [playVideo, setPlayVideo] = useState(false);


    function convertSecondstoTime(given_seconds) {
      var hours = Math.floor(given_seconds / 3600);
      var minutes = Math.floor((given_seconds - hours * 3600) / 60);
      var seconds = Math.floor(given_seconds - hours * 3600 - minutes * 60);
  
      hours > 0
        ? (hours = hours?.toString().padStart(2, "0") + ":")
        : (hours = "");
      minutes > 0
        ? (minutes = minutes?.toString().padStart(2, "0") + ":")
        : (minutes = ":");
      seconds > 0
        ? (seconds = seconds?.toString().padStart(2, "0"))
        : (seconds = "");
  
      var timeString =
        hours.length > 0
          ? hours?.toString().padStart(2, "0") + ":"
          : "" +
            minutes?.toString().padStart(2, "0") +
            seconds?.toString().padStart(2, "0");
  
      return timeString;
    }


    function handlePlayVideo() {
        setPlayVideo(!playVideo);   
        // https://youtu.be/r-GSGH2RxJs?si=-DZ9k7-oWGrcDp5I&t=47
        // t={paragraph[0]} 
        // https://youtu.be/r-GSGH2RxJs?si=GMYzJ2spzyHsb5Pb&t=59
        // https://youtu.be/r-GSGH2RxJs?si=NsOdx51h2kUzpRIx&t=81
      }

  return (
    <>
    <Link href={`${videoUrl}&t=${Math.floor(paragraph[0])}`} target="_blank">
    <Chip
      icon={!playVideo ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
      label={convertSecondstoTime(paragraph[0])}
      variant="outlined"
      onClick={handlePlayVideo}
      color="success"
    // sx={{color:"#212121!important"}}
    />
    </Link>
    {/* {playVideo && (
      <Typography>Playing video from {paragraph[0]}</Typography>
    )} */}
    <Typography key={index}>{paragraph[1]}</Typography>
    <br />
  </>
  );
}