import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

export default function LearningProfile({email}) {
    const [videoCount, setVideoCount] = useState(0);

    useEffect(() => {
        // get count of videos  
        async function getVideoCount() {
            const videoCount = await fetch(
                `${process.env.REACT_APP_YTL_BE}/api/videos?email=${email}&count=1&debug=2count`,
                {
                    method: "GET",
                }
            );
            const data = await videoCount.json();
            // debugger;
            console.log(`videoCount: ${data.length}`)
            setVideoCount(data.length);
        } 
        videoCount === 0 && email && getVideoCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        <Typography variant="h2">Learning Profile</Typography>
        <Typography>You are learning from {videoCount} {videoCount === 1 ? "video" : "videos"}</Typography>
        </>
    )

}