import React, { useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Container, Avatar, Button, Typography  } from "@mui/material";
import { getPostHeaders } from "../utils/headers";
import axios from "axios";
  
function CustomGoogleLoginButton({
  showLoading,
  showTranscriptionCard,
  profile,
  handleSetProfile,  
}) {
  
  
  useEffect(() => {
    async function refreshProfile() {
      
      const userInfo = localStorage.getItem("userInfo");    
      if (userInfo && handleSetProfile) {
        handleSetProfile(JSON.parse(userInfo));
      }
    }
    refreshProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoading]);

  
  useEffect(() => {
    async function handleUser() {
      if (profile?.email && profile?.given_name !== "Guest") {        
        let headers = getPostHeaders({ profile: profile }, "POST");
        const response = await fetch(
          `${process.env.REACT_APP_YTL_BE}/api/user`,
          { ...headers }
        );
        // if (response.status === 401) {context.logout({})}
        const data = await response.json();
        console.log(data);           
      }       
    }   
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);



  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      console.log(userInfo);
      handleSetProfile(userInfo.data);
      localStorage.setItem("_og_k_", tokenResponse.access_token);
      localStorage.setItem("userInfo", JSON.stringify(userInfo.data));
    },
    onError: (errorResponse) => console.log(errorResponse),
  });
  
  return (
    <>
      {profile?.email ? (
        <Container
          sx={{            
            float: "right",
            marginRight: "0px",            
            width: "auto",
            padding: "18px",
            
          }}
        >
            <Avatar alt={profile?.name} src={profile ? profile.picture : ""} 
              sx={{
                width: "3rem",
                height: "3rem",

              }}/>          
        </Container>
      ) : (
        <>
        <Button
          onClick={googleLogin}
          sx={{
            marginTop: "25px",
            float: "right",
            margin: "auto",
            padding: "18px",
            color: "white",
            // transform:"translate(-0%, -50%)",
            visibility:
              showLoading || showTranscriptionCard ? "hidden" : "visible",
            display: showLoading || showTranscriptionCard ? "none" : "",
          }}
        >          
          <svg className={"Bz112c Bz112c-E3DyYd"} xmlns="https://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="#4285F4" d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z"></path><path fill="#34A853" d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"></path><path fill="#FBBC05" d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z"></path><path fill="#EA4335" d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"></path><path fill="none" d="M2 2h44v44H2z"></path></svg>
          <Typography sx={{paddingLeft:".5em"}}>Sign in with Google</Typography>
        </Button>        
          </>
      )}
    </>
  );
}
export default CustomGoogleLoginButton;
