import React from "react";
import { Typography } from "@mui/material";

export default function ComponentNameDebug({component}){
    return (
        <Typography
        variant="h6"
        noWrap
        component="div"
        // display:"none"
        sx={{position:"relative", top:"0", backgroundColor: "red", color: "white", display:"none"}}
      >
        {component}
      </Typography>  

    )
}