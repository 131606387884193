// Main page to configure:
// - which playlist to use
// - frequency of updates
// For each video in the playlist, the following is displayed:
// - title
// - description
// - thumbnail
// - tags
// - publish date
// - Summary
// - Transcript
// - Bullet points with timestamps
// Learning options:
// - create tl;dr
// - create summary
// - create bullet points
// - Create flashcards
// - Create quiz
// - Create mindmap
// - Enable spaced repetition

import React from "react";
import { Typography } from "@mui/material";
import { Grid, Box, TextField, Button } from "@mui/material";
import axios from "axios";

// TODO: Not used right now,, this functionlity is in AddPlaylist.js

export default function AddSingleVideo({ email, refreshCallback }) {
  // const ogId = localStorage.getItem("_og_id");
  // debugger;
  
  // const handleAddPlaylist = () => {    
  //   async function addPlaylist() {
  //     const videoUrl = document.getElementById("videoUrl").value;
  //     console.log("addPlaylist");
  //     // As a guest, going to use the ogId to create a playlist
  //     const playlist = await axios.post(
  //       `${process.env.REACT_APP_YTL_BE}/api/playlists`,
  //       {
  //         // playlistUrl: `https://ytlearn.ai/guest?list=${ogId}`,
  //         playlistUrl: videoUrl,
  //         email: email,
  //       }
  //     );
  //     console.log(playlist);      
  //     callback();
  //   }
  //   addPlaylist();
  // };

  const handleAddVideo = () => {
    async function addVideo() {
      const videoUrl = document.getElementById("videoUrl").value;      
      const video = await axios.post(
        `${process.env.REACT_APP_YTL_BE}/api/videos`,
        {  
          videoUrl: videoUrl,
          email: email,
        }
      );
      console.log(video);
      // callback();
      refreshCallback();
    }
    addVideo();
  };


  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            marginLeft: "4rem",
            marginRight: "4rem",
            marginTop: "1rem",
            marginBottom: "5rem",
          }}
        >
          <Box component="h1" sx={{ padding: "1%", color: "#003366" }}>
          Supercharge Your Learning Journey!
          </Box>
          <Typography>
          Have a YouTube video in mind that you've been eager to learn from? Simply paste the URL below, and we'll craft a personalized learning experience based on the content. Dive into a world of retention, application, and growth.
          </Typography>
          <Box component="h2" sx={{ padding: "1%", color: "#003366" }}>
            Start Learning Right Away by Entering a YouTube URL
          </Box>
          <Box sx={{ width: "80%", display: "flex" }}>
            <TextField
              id="videoUrl"
              label="Video URL"
              placeholder="https://www.youtube.com/watch?v=iKYHf22qVdM"
              variant="outlined"
              fullWidth={true}
            />
            <Button
              onClick={handleAddVideo}
              variant="contained"
              sx={{
                marginLeft: "1rem",
                backgroundColor: "#003366",
                "&:hover": { backgroundColor: "#E91E63" },
              }}
            >
              Go!
            </Button>
          </Box>
          
        </Grid>
      </Grid>
    </div>

  
  );
}
