import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function AccountOptions({handleMenuSelect, isAuthenticated}) {
    const linkStyle = {
        pointerEvents: isAuthenticated ? "auto" : "none",
        cursor: isAuthenticated ? "pointer" : "default",
        opacity: isAuthenticated ? "inherit" : "0.6",
      };
      
    return (
      <List>
      {["Home", "Account", "Logout"].map((text, index) => (
        <Link
          key={index}
          onClick={handleMenuSelect}
          underline="hover"
          color="#212121"
          sx={linkStyle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index === 0 ? (
                  <HomeIcon />
                ) : index === 1 ? (
                  <AccountCircleIcon />
                ) : (
                  <LogoutIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
    )
}
